import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import { CTAStandard, Heading1 } from '../../../Typography/Typography.styles';

const ActiveStyles = (darkMode: boolean) => css`
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: ${({ theme }) =>
      darkMode ? theme.colors.white : theme.colors.black};
    margin-top: 2px;
  }
`;

export const Link = styled(NextLink)<{
  $isActive?: boolean;
  $darkMode: boolean;
}>`
  font-family: ${({ theme }) => theme.fontFamily.main};
  justify-content: flex-start;
  color: ${({ $darkMode, theme }) =>
    $darkMode ? theme.colors.white : theme.colors.black};
  ${CTAStandard};
  margin-right: 0;
  padding: 10px 12px ${({ $isActive }) => ($isActive ? '5px' : '10px')};
  text-decoration: none;
  border-radius: 5px;

  ${({ $darkMode, $isActive }) => $isActive && ActiveStyles($darkMode)}

  &:hover {
    background: ${({ theme, $darkMode }) =>
      $darkMode ? theme.colors.gray900 : theme.colors.gray50};
  }

  @media ${({ theme }) => theme.breakpoints.large} {
    &:not(:last-of-type) {
      margin-right: 35px;
    }
  }
`;

export const DrawerLink = styled(Link)`
  padding: 0;
  margin-bottom: 10px;
  ${Heading1};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.black};
  }
`;
