import LinkWithAccordionMenu from './LinkWithAccordionMenu';
import LinkSimple from './LinkSimple';
import { Container, StyledModal } from './PrimaryNavSideBar.styles';
import { Link } from '../types';

type PrimaryNavSideBarProps = {
  onClose?: () => void;
  open: boolean;
  links: Link[];
  setActiveMenu: (id: string) => void;
};

const PrimaryNavSideBar = ({
  open,
  onClose,
  links,
  setActiveMenu,
}: PrimaryNavSideBarProps) => {
  return (
    <StyledModal
      open={open}
      onClose={() => onClose && onClose()}
      hideCloseBtn
      className="sidebar_modal"
    >
      <Container>
        {links.map((linkItem) =>
          linkItem.sublinks && linkItem.sublinks.length ? (
            <LinkWithAccordionMenu
              key={`sideBar_${linkItem?.title}_${linkItem}`}
              title={linkItem.title}
              id={linkItem.title}
              subMenu={linkItem.sublinks}
              setActiveMenu={setActiveMenu}
            />
          ) : (
            <LinkSimple
              key={`sideBar_${linkItem?.title}_${linkItem}`}
              {...linkItem}
              href={linkItem.url || ''}
              isDrawer
            />
          ),
        )}
      </Container>
    </StyledModal>
  );
};

export default PrimaryNavSideBar;
