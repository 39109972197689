import styled from 'styled-components';
import NextLink from 'next/link';
import Typography from '../../../Typography';
import Accordion from '../../../Accordion';
import { Heading1 } from '../../../Typography/Typography.styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const StyledAccordion = styled(Accordion)`
  .accordion_header {
    width: auto;
    padding: 0;
    border: none;
    border-radius: 5px;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 10px;
    &:hover {
      background: ${({ theme }) => theme.colors.black};
    }
  }
  .accordion_content {
    > div {
      padding: 0;
    }
  }
  margin-bottom: 5px;
`;

export const AccordionHeading = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily.main};
  ${Heading1};
  color: ${(props) => props.theme.colors.white};
  margin: 0;
`;

export const Link = styled(NextLink)<{ $isActive?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.main};
  justify-content: flex-start;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.machineGreen600 : theme.colors.gray400};
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  padding: 0 12px;
  text-decoration: none;
  margin-bottom: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.black};
  }
`;
