import { Fragment, PropsWithChildren } from 'react';
import { Portal } from '@radix-ui/react-popover';

import {
  MenuWrapper,
  MenuListItem,
  MenuButtonWrapper,
  MenuListWrapper,
  MenuItemWrapper,
  MenuButtonInner,
  Border,
  ContextLabel,
  Divider,
  ContextSubLabel,
} from './ContextMenu.styles';
import Vertical3DotsIcon from '../icons/Vertical3DotsIcon';
import theme from '../../themes/theme';

export type ContextMenuType = {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  label?: string;
  onSelect?: () => void;
  link?: string;
  external?: boolean;
  color?: string;
  border?: boolean;
};

export type ContextMenuProps = {
  items: ContextMenuType[];
  contextLabel?: string;
  contextSubLabel?: string;
  className?: string;
  shouldUsePortal?: boolean;
  align?: 'start' | 'center' | 'end';
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  onCloseAutoFocus?: (event: Event) => void;
};

const ContextMenu = ({
  items,
  children,
  contextLabel,
  contextSubLabel,
  className,
  shouldUsePortal,
  align,
  onOpenChange,
  open,
  onCloseAutoFocus,
  ...otherProps
}: PropsWithChildren<ContextMenuProps>) => {
  if (!items || items?.length < 1) {
    return <>{children}</>;
  }
  const ContentWrapper = shouldUsePortal ? Portal : Fragment;
  return (
    <MenuWrapper onOpenChange={onOpenChange} open={open}>
      <MenuButtonWrapper
        {...otherProps}
        className={`${className || ''} context_menu_button_wrapper`}
      >
        {!children && (
          <MenuButtonInner>
            <span aria-hidden>
              <Vertical3DotsIcon color={theme.colors.machineGreen600} />
            </span>
          </MenuButtonInner>
        )}
        {children}
      </MenuButtonWrapper>
      <ContentWrapper>
        <MenuListWrapper
          align={align}
          className={className}
          onCloseAutoFocus={onCloseAutoFocus}
        >
          {contextLabel && (
            <ContextLabel className="context_label">
              {contextLabel}
            </ContextLabel>
          )}
          {contextSubLabel && (
            <ContextSubLabel className="context_sub_label">
              {contextSubLabel}
            </ContextSubLabel>
          )}
          {(contextLabel || contextSubLabel) && <Divider />}

          {items.map((item, index) => (
            <MenuListItem
              key={index}
              data-testid={`context-menu-item-${index}`}
            >
              {item?.link ? (
                <MenuItemWrapper
                  className="context_menu_item"
                  external={item.external}
                  href={item.link}
                  $textColor={item?.color}
                  icon={item?.icon}
                  iconPosition={item?.iconPosition || 'left'}
                >
                  <div>{item?.label}</div>
                </MenuItemWrapper>
              ) : (
                <>
                  {item?.border && <Border />}
                  <MenuItemWrapper
                    onClick={() => item?.onSelect && item.onSelect()}
                    $textColor={item?.color}
                    className="context_menu_item"
                    icon={item?.icon}
                    iconPosition={item?.iconPosition || 'left'}
                  >
                    <div>{item?.label}</div>
                  </MenuItemWrapper>
                </>
              )}
            </MenuListItem>
          ))}
        </MenuListWrapper>
      </ContentWrapper>
    </MenuWrapper>
  );
};

export default ContextMenu;
