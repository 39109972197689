import Image from 'next/image';
import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import CloseIcon from '../../icons/CloseIcon';
import MenuIcon from '../../icons/MenuIcon';
import {
  ButtonsContainer,
  InnerContainer,
  LinkContainer,
  LogoWrapper,
  OuterContainer,
  SideBarContainer,
  SideMenuButton,
} from './PrimaryNav.styles';
import logo from '../../../assets/wreno-logo-with-name.png';
import logoDark from '../../../assets/wreno-logo-with-name-yellow.png';
import PrimaryNavLink from './components/PrimayNavLink';
import PrimaryNavSideBar from './components/PrimaryNavSideBar';
import { useRouter } from 'next/router';
import { Link as ILink } from './types';
import AnnouncementBar, { Announcement } from '../../AnnouncementBar';
import useAnnouncementBar from '../../../hooks/useAnnouncementBar';
import { useTheme } from 'styled-components';
import { useDarkMode } from '../../../hooks/useDarkMode';

interface Props {
  isSticky?: boolean;
  backgroundColor?: string;
  links?: ILink[];
  buttons?: React.ReactNode;
  announcementBar?: Announcement | null;
  mobileNavLinks?: ILink[];
}

const PrimaryNav = ({
  isSticky,
  backgroundColor,
  links,
  buttons,
  announcementBar,
  mobileNavLinks,
}: Props) => {
  const [activeMenu, setActiveMenu] = useState<string>();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { asPath } = useRouter();
  const { isVisible, onClose } = useAnnouncementBar(announcementBar);
  const theme = useTheme();
  const { isLarge, isRendering } = useResponsive();
  const { darkMode } = useDarkMode();

  const handleSidebarOpen = useCallback(() => {
    setSideBarOpen(true);
    if (activeMenu) {
      setActiveMenu(undefined);
    }
  }, [activeMenu, setSideBarOpen]);

  const handleSidebarClose = useCallback(() => {
    setSideBarOpen(false);
  }, [setSideBarOpen]);

  const open = isLarge ? false : sideBarOpen;

  const linksToDisplay = (!isRendering && isLarge && links) || [];

  const handleSetActiveMenu = useCallback(
    (id: string | undefined) => {
      if (activeMenu === id) {
        setActiveMenu(undefined);
      } else {
        setActiveMenu(id);
      }
    },
    [activeMenu, setActiveMenu],
  );

  const handleSetActiveMenuWithSidebar = useCallback(
    (id: string | undefined) => {
      handleSetActiveMenu(id);

      if (sideBarOpen) {
        setSideBarOpen(false);
      }
    },
    [sideBarOpen, handleSetActiveMenu],
  );

  useEffect(() => {
    setSideBarOpen(false);
    setActiveMenu(undefined);
  }, [isLarge, asPath]);

  const SideBarMenuIcon = sideBarOpen ? CloseIcon : MenuIcon;
  return (
    <>
      <OuterContainer
        $isSticky={isSticky}
        $sidebarOpen={sideBarOpen}
        $backgroundColor={backgroundColor || theme.colors.white}
        $announcementIsVisible={isVisible && !sideBarOpen}
      >
        {announcementBar && isVisible && !sideBarOpen && (
          <AnnouncementBar
            label={announcementBar.text}
            actionLabel={announcementBar.link?.label}
            href={announcementBar.link?.url}
            variant={announcementBar.variant}
            onClose={onClose}
          />
        )}
        <InnerContainer>
          <LogoWrapper>
            <Link href="/">
              <Image
                src={sideBarOpen || darkMode ? logoDark : logo}
                width={147}
                height={40}
                alt="Wreno Logo"
                priority
                data-testid="nav-logo"
              />
            </Link>
          </LogoWrapper>
          <LinkContainer>
            {linksToDisplay.map((link, index) => (
              <PrimaryNavLink
                key={`${link?.title}_${index}`}
                linkItem={link}
                activeMenu={activeMenu}
                onClick={handleSetActiveMenuWithSidebar}
              />
            ))}
          </LinkContainer>
          {isLarge && <ButtonsContainer>{buttons}</ButtonsContainer>}
          {!isLarge && (
            <SideBarContainer>
              <SideMenuButton
                onClick={() =>
                  sideBarOpen ? handleSidebarClose() : handleSidebarOpen()
                }
                aria-label="Menu"
                variant="tertiary"
                icon={
                  <SideBarMenuIcon
                    focusable="false"
                    height={20}
                    width={20}
                    color={
                      sideBarOpen || darkMode
                        ? theme.colors.white
                        : theme.colors.black
                    }
                    aria-hidden="true"
                    data-testid="MenuIcon"
                  />
                }
                iconPosition="left"
              />
            </SideBarContainer>
          )}
        </InnerContainer>
      </OuterContainer>
      <PrimaryNavSideBar
        links={mobileNavLinks || links || []}
        open={open}
        onClose={handleSidebarClose}
        setActiveMenu={handleSetActiveMenu}
      />
    </>
  );
};

export default PrimaryNav;
