import { ReactNode } from 'react';
import Footer from 'styleguide/src/components/Footer';
import PrimaryNav from 'styleguide/src/components/website/PrimaryNav';
import theme, { ThemeColor } from 'styleguide/src/themes/theme';
import { isWrenoApp } from 'utils/app';
import Button from 'styleguide/src/components/Button/Button';
import { LayoutProps } from 'utils/layoutData';
import styled from 'styled-components';
import getHeaderHeight from 'styleguide/src/utils/getHeaderHeight';
import AnnouncementBarProvider, {
  useAnnouncementBarContext,
} from 'common/providers/AnnoucementBarProvider';
import {
  DarkModeProvider,
  useDarkMode,
} from 'styleguide/src/hooks/useDarkMode';
import Typography from 'styleguide/src/components/Typography';
import { SUPPORT_LINK } from 'common/utils/mappings/supportLink';

const Container = styled.div<{ $announcementIsVisible?: boolean }>`
  min-height: calc(
    100vh - ${getHeaderHeight(true)}px -
      ${({ theme }) => theme.variables.footerHeightMobile}px
  );

  @media ${({ theme }) => theme.breakpoints.large} {
    min-height: calc(
      100vh - ${getHeaderHeight()}px -
        ${({ theme }) => theme.variables.footerHeight}px
    );
  }
`;

interface MainProp extends LayoutProps {
  children?: ReactNode;
  colorInvert?: boolean;
  navColor?: string;
}

const Content = ({
  children,
  navColor,
  navLinks,
  footerLinks,
  announcementBar,
  mobileNavLinks,
}: MainProp) => {
  const isApp = isWrenoApp();
  const { showAnnouncementBar } = useAnnouncementBarContext();
  const { darkMode } = useDarkMode();

  if (isApp) {
    return <main>{children}</main>;
  }
  return (
    <>
      <PrimaryNav
        isSticky
        backgroundColor={
          navColor ? theme.colors[navColor as ThemeColor] : undefined
        }
        buttons={
          <>
            <Button
              variant={'tertiary'}
              href={process.env.NEXT_PUBLIC_WRENO_APP_URL}
            >
              <Typography tagName="span" color={darkMode ? 'white' : 'black'}>
                Login
              </Typography>
            </Button>
            <Button
              variant={darkMode ? 'primary-yellow' : 'primary'}
              href={SUPPORT_LINK}
            >
              Contact Us
            </Button>
          </>
        }
        links={navLinks}
        mobileNavLinks={mobileNavLinks}
        announcementBar={announcementBar}
      />
      <main>
        <Container $announcementIsVisible={showAnnouncementBar}>
          {children}
        </Container>
      </main>
      <Footer links={footerLinks} />
    </>
  );
};

const Layout = ({
  children,
  navColor,
  navLinks,
  footerLinks,
  announcementBar,
  mobileNavLinks,
}: MainProp) => {
  return (
    <DarkModeProvider darkMode={navColor === 'black'}>
      <AnnouncementBarProvider>
        <Content
          mobileNavLinks={mobileNavLinks}
          navColor={navColor}
          navLinks={navLinks}
          footerLinks={footerLinks}
          announcementBar={announcementBar}
        >
          {children}
        </Content>
      </AnnouncementBarProvider>
    </DarkModeProvider>
  );
};

export default Layout;
