import { PropsWithChildren } from 'react';
import { ButtonProps } from '../../Button/Button';

import {
  DrawerModal,
  DrawerContainer,
  DrawerBody,
  DrawerScrollContainer,
  DrawerActions,
  DrawerTitleRow,
  DrawerCloseButton,
  DrawerActionButton,
} from './Drawer.styles';

import { ModalProps } from '../Modal';
import { TypographyVariant } from '../../Typography/Typography.styles';
import CloseIcon from '../../icons/CloseIcon';
import Typography from '../../Typography';

interface DrawerProps extends PropsWithChildren<ModalProps> {
  title: string;
  titleVariant?: TypographyVariant;
  actions?: (ButtonProps & { key: string })[];
}

export default function Drawer(props: DrawerProps) {
  const { title, actions, children, titleVariant, ...rest } = props;

  return (
    <DrawerModal hideCloseBtn {...rest}>
      <DrawerContainer>
        <DrawerBody>
          <DrawerScrollContainer>
            <DrawerTitleRow>
              <Typography variant={titleVariant ?? 'heading3'}>
                {title}
              </Typography>
              <DrawerCloseButton
                onClick={rest.onClose}
                data-testid="modal-close-button"
                id="close-button"
              >
                <CloseIcon />
              </DrawerCloseButton>
            </DrawerTitleRow>
            {children}
          </DrawerScrollContainer>
        </DrawerBody>
        {actions && (
          <DrawerActions>
            {actions.map(({ key, title, ...buttonProps }) => (
              <DrawerActionButton key={key} {...buttonProps} />
            ))}
          </DrawerActions>
        )}
      </DrawerContainer>
    </DrawerModal>
  );
}
