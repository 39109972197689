// hooks
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

// types
import type { ReactNode } from 'react';

interface AnnouncementBarProviderProps {
  children: ReactNode;
}

interface AnnouncementBarContextType {
  showAnnouncementBar: boolean;
  setShowAnnouncementBar: Dispatch<SetStateAction<boolean>>;
}

export const useAnnouncementBarContext = () => {
  const { showAnnouncementBar, setShowAnnouncementBar } = useContext(
    AnnouncementBarContext,
  );

  return {
    showAnnouncementBar,
    setShowAnnouncementBar,
  };
};

const AnnouncementBarContext = createContext<AnnouncementBarContextType>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  { showAnnouncementBar: false, setShowAnnouncementBar: () => {} },
);

const AnnouncementBarProvider = ({
  children,
}: AnnouncementBarProviderProps) => {
  const [showAnnouncementBar, setShowAnnouncementBar] = useState(false);

  return (
    <AnnouncementBarContext.Provider
      value={{
        showAnnouncementBar,
        setShowAnnouncementBar,
      }}
    >
      {children}
    </AnnouncementBarContext.Provider>
  );
};

export default AnnouncementBarProvider;
